#TABLE_1 {
    block-size: 77px;
    border-block-end-color: rgb(224, 230, 232);
    border-block-end-style: solid;
    border-block-end-width: 1px;
    border-block-start-color: rgb(224, 230, 232);
    border-block-start-style: solid;
    border-block-start-width: 1px;
    border-collapse: collapse;
    border-inline-end-color: rgb(224, 230, 232);
    border-inline-end-style: solid;
    border-inline-end-width: 1px;
    border-inline-start-color: rgb(224, 230, 232);
    border-inline-start-style: solid;
    border-inline-start-width: 1px;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    height: 77px;
    inline-size: 748px;
    perspective-origin: 374px 38.5px;
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    transform-origin: 374px 38.5px;
    width: 100%;
    border: 1px solid rgb(224, 230, 232);
    border-spacing: 0px 0px;
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TABLE_1*/

#TABLE_1:after {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    border-spacing: 0px 0px;
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TABLE_1:after*/

#TABLE_1:before {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    border-spacing: 0px 0px;
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TABLE_1:before*/

#THEAD_2 {
    block-size: 36px;
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    height: 36px;
    inline-size: 747px;
    perspective-origin: 373.5px 18px;
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    transform-origin: 373.5px 18px;
    vertical-align: baseline;
    width: 747px;
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#THEAD_2*/

#THEAD_2:after {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#THEAD_2:after*/

#THEAD_2:before {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#THEAD_2:before*/

#TR_3 {
    block-size: 36px;
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    height: 36px;
    inline-size: 747px;
    perspective-origin: 373.5px 18px;
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    transform-origin: 373.5px 18px;
    width: 747px;
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TR_3*/

#TR_3:after {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TR_3:after*/

#TR_3:before {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TR_3:before*/

#TH_4, #TH_5, #TH_7, #TH_9, #TH_11, #TH_13, #TH_15 {
    block-size: 36px;
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    height: 36px;
    text-align: center;
    inline-size: 62.25px;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-inline-end: 0px;
    padding-inline-start: 0px;
    perspective-origin: 31.125px 18px;
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    transform-origin: 31.125px 18px;
    vertical-align: middle;
    width: 62.25px;
    background: rgb(246, 248, 249) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
    padding: 0px;
}/*#TH_4, #TH_5, #TH_7, #TH_9, #TH_11, #TH_13, #TH_15*/

#TH_4:after, #TH_5:after, #TH_7:after, #TH_9:after, #TH_11:after, #TH_13:after, #TH_15:after {
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
}/*#TH_4:after, #TH_5:after, #TH_7:after, #TH_9:after, #TH_11:after, #TH_13:after, #TH_15:after*/

#TH_4:before, #TH_5:before, #TH_7:before, #TH_9:before, #TH_11:before, #TH_13:before, #TH_15:before {
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
}/*#TH_4:before, #TH_5:before, #TH_7:before, #TH_9:before, #TH_11:before, #TH_13:before, #TH_15:before*/

#TH_6, #TH_8, #TH_12 {
    block-size: 36px;
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    height: 36px;
    inline-size: 62.25px;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-inline-end: 0px;
    padding-inline-start: 0px;
    perspective-origin: 31.125px 18px;
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    transform-origin: 31.125px 18px;
    vertical-align: middle;
    width: 62.25px;
    background: rgb(246, 248, 249) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
    padding: 0px;
}/*#TH_6, #TH_8, #TH_12*/

#TH_6:after, #TH_8:after, #TH_12:after {
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
}/*#TH_6:after, #TH_8:after, #TH_12:after*/

#TH_6:before, #TH_8:before, #TH_12:before {
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
}/*#TH_6:before, #TH_8:before, #TH_12:before*/

#TH_10, #TH_14 {
    block-size: 36px;
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    height: 36px;
    inline-size: 62.25px;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-inline-end: 0px;
    padding-inline-start: 0px;
    perspective-origin: 31.125px 18px;
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    transform-origin: 31.125px 18px;
    vertical-align: middle;
    width: 62.25px;
    background: rgb(246, 248, 249) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
    padding: 0px;
}/*#TH_10, #TH_14*/

#TH_10:after, #TH_14:after {
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
}/*#TH_10:after, #TH_14:after*/

#TH_10:before, #TH_14:before {
    border-block-end-color: rgb(132, 143, 153);
    border-block-start-color: rgb(132, 143, 153);
    border-collapse: collapse;
    border-inline-end-color: rgb(132, 143, 153);
    border-inline-start-color: rgb(132, 143, 153);
    box-sizing: border-box;
    caret-color: rgb(132, 143, 153);
    color: rgb(132, 143, 153);
    column-rule-color: rgb(132, 143, 153);
    text-decoration: none solid rgb(132, 143, 153);
    text-emphasis-color: rgb(132, 143, 153);
    border: 0px none rgb(132, 143, 153);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(132, 143, 153) none 0px;
}/*#TH_10:before, #TH_14:before*/

#TBODY_16 {
    block-size: 40px;
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    height: 40px;
    inline-size: 747px;
    perspective-origin: 373.5px 20px;
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    transform-origin: 373.5px 20px;
    vertical-align: baseline;
    width: 747px;
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TBODY_16*/

#TBODY_16:after {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TBODY_16:after*/

#TBODY_16:before {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TBODY_16:before*/

#TR_17 {
    block-size: 40px;
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    height: 40px;
    inline-size: 747px;
    perspective-origin: 373.5px 20px;
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    transform-origin: 373.5px 20px;
    width: 747px;
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TR_17*/

#TR_17:after {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TR_17:after*/

#TR_17:before {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TR_17:before*/

#TD_18, #TD_19, #TD_21, #TD_23, #TD_25, #TD_27, #TD_29 {
    block-size: 40px;
    border-block-end-color: rgb(224, 230, 232);
    border-block-end-style: solid;
    border-block-end-width: 1px;
    border-block-start-color: rgb(224, 230, 232);
    border-block-start-style: solid;
    border-block-start-width: 1px;
    border-collapse: collapse;
    border-inline-end-color: rgb(224, 230, 232);
    border-inline-end-style: solid;
    border-inline-end-width: 1px;
    border-inline-start-color: rgb(224, 230, 232);
    border-inline-start-style: solid;
    border-inline-start-width: 1px;
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    height: 40px;
    inline-size: 62.25px;
    padding-block-end: 5px;
    padding-block-start: 5px;
    padding-inline-end: 3px;
    padding-inline-start: 4px;
    perspective-origin: 31.125px 20px;
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    transform-origin: 31.125px 20px;
    width: 62.25px;
    border: 1px solid rgb(224, 230, 232);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
    padding: 5px 3px 5px 4px;
}/*#TD_18, #TD_19, #TD_21, #TD_23, #TD_25, #TD_27, #TD_29*/

#TD_18:after, #TD_19:after, #TD_21:after, #TD_23:after, #TD_25:after, #TD_27:after, #TD_29:after {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TD_18:after, #TD_19:after, #TD_21:after, #TD_23:after, #TD_25:after, #TD_27:after, #TD_29:after*/

#TD_18:before, #TD_19:before, #TD_21:before, #TD_23:before, #TD_25:before, #TD_27:before, #TD_29:before {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TD_18:before, #TD_19:before, #TD_21:before, #TD_23:before, #TD_25:before, #TD_27:before, #TD_29:before*/

#TD_20, #TD_22, #TD_26 {
    block-size: 40px;
    border-block-end-color: rgb(224, 230, 232);
    border-block-end-style: solid;
    border-block-end-width: 1px;
    border-block-start-color: rgb(224, 230, 232);
    border-block-start-style: solid;
    border-block-start-width: 1px;
    border-collapse: collapse;
    border-inline-end-color: rgb(224, 230, 232);
    border-inline-end-style: solid;
    border-inline-end-width: 1px;
    border-inline-start-color: rgb(224, 230, 232);
    border-inline-start-style: solid;
    border-inline-start-width: 1px;
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    height: 40px;
    inline-size: 62.25px;
    padding-block-end: 5px;
    padding-block-start: 5px;
    padding-inline-end: 3px;
    padding-inline-start: 4px;
    perspective-origin: 31.125px 20px;
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    transform-origin: 31.125px 20px;
    width: 62.25px;
    border: 1px solid rgb(224, 230, 232);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
    padding: 5px 3px 5px 4px;
}/*#TD_20, #TD_22, #TD_26*/

#TD_20:after, #TD_22:after, #TD_26:after {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TD_20:after, #TD_22:after, #TD_26:after*/

#TD_20:before, #TD_22:before, #TD_26:before {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TD_20:before, #TD_22:before, #TD_26:before*/

#TD_24, #TD_28 {
    block-size: 40px;
    border-block-end-color: rgb(224, 230, 232);
    border-block-end-style: solid;
    border-block-end-width: 1px;
    border-block-start-color: rgb(224, 230, 232);
    border-block-start-style: solid;
    border-block-start-width: 1px;
    border-collapse: collapse;
    border-inline-end-color: rgb(224, 230, 232);
    border-inline-end-style: solid;
    border-inline-end-width: 1px;
    border-inline-start-color: rgb(224, 230, 232);
    border-inline-start-style: solid;
    border-inline-start-width: 1px;
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    height: 40px;
    inline-size: 62.25px;
    padding-block-end: 5px;
    padding-block-start: 5px;
    padding-inline-end: 3px;
    padding-inline-start: 4px;
    perspective-origin: 31.125px 20px;
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    transform-origin: 31.125px 20px;
    width: 62.25px;
    border: 1px solid rgb(224, 230, 232);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
    padding: 5px 3px 5px 4px;
}/*#TD_24, #TD_28*/

#TD_24:after, #TD_28:after {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TD_24:after, #TD_28:after*/

#TD_24:before, #TD_28:before {
    border-block-end-color: rgb(32, 37, 44);
    border-block-start-color: rgb(32, 37, 44);
    border-collapse: collapse;
    border-inline-end-color: rgb(32, 37, 44);
    border-inline-start-color: rgb(32, 37, 44);
    box-sizing: border-box;
    caret-color: rgb(32, 37, 44);
    color: rgb(32, 37, 44);
    column-rule-color: rgb(32, 37, 44);
    text-decoration: none solid rgb(32, 37, 44);
    text-emphasis-color: rgb(32, 37, 44);
    border: 0px none rgb(32, 37, 44);
    font: 12px / 16px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(32, 37, 44) none 0px;
}/*#TD_24:before, #TD_28:before*/

#DIV_1 {
    block-size: 30px;
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    height: 30px;
    inline-size: 499px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    perspective-origin: 249.5px 15px;
    position: relative;
    right: 0px;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    top: 0px;
    transform-origin: 249.5px 15px;
    width: 499px;
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_1*/

#DIV_1:after {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0) repeating-linear-gradient(-30deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1px, rgb(138, 201, 255) 2px, rgb(138, 201, 255) 3px, rgba(0, 0, 0, 0) 4px) repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_1:after*/

#DIV_1:before {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_1:before*/

#DIV_2 {
    block-size: 30px;
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    height: 30px;
    inline-size: 499px;
    perspective-origin: 249.5px 15px;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    transform-origin: 249.5px 15px;
    width: 499px;
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_2*/

#DIV_2:after {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    display: block;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_2:after*/

#DIV_2:before {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    display: block;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_2:before*/

#DIV_3 {
    block-size: 30px;
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-end-start-radius: 3px;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    border-start-start-radius: 3px;
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    height: 30px;
    inline-size: 124.75px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    perspective-origin: 62.375px 15px;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    transform-origin: 62.375px 15px;
    width: 124.75px;
    background: rgb(129, 194, 255) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(255, 255, 255);
    border-radius: 3px 0px 0px 3px;
    flex: 2 1 auto;
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_3*/

#DIV_3:after {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_3:after*/

#DIV_3:before {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_3:before*/

#DIV_4 {
    block-size: 30px;
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-radius: 3px !important;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    height: 30px;
    inline-size: 374.25px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    perspective-origin: 187.125px 15px;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    transform-origin: 187.125px 15px;
    background: rgb(65, 162, 255) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(255, 255, 255);
    border-radius: 0px 3px 3px 0px;
    flex: 6 1 auto;
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
    width: 100%;
}/*#DIV_4*/

#DIV_4:after {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_4:after*/

#DIV_4:before {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_4:before*/

#DIV_5 {
    block-size: 14px;
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    bottom: 3px;
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    height: 14px;
    inline-size: 371px;
    inset-block-end: 3px;
    inset-block-start: 13px;
    inset-inline-end: 0px;
    inset-inline-start: 128px;
    left: 128px;
    padding-inline-end: 6px;
    padding-inline-start: 6px;
    perspective-origin: 185.5px 7px;
    position: absolute;
    right: 0px;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    text-wrap: nowrap;
    top: 13px;
    transform-origin: 185.5px 7px;
    width: 371px;
    z-index: 1;
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
    overflow: hidden;
    padding: 0px 6px;
}/*#DIV_5*/

#DIV_5:after {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-wrap: nowrap;
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_5:after*/

#DIV_5:before {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-collapse: collapse;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-wrap: nowrap;
    border: 0px none rgb(255, 255, 255);
    font: 10px / 14px Roboto, Helvetica, Verdana, Geneva, Arial, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_5:before*/

