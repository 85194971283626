.accordion-button:not(.collapsed),
.accordion-button:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}
.accordion-button::after {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: var(--bs-gray-300);
  background-image: none !important;
}
.accordion-item.open  .accordion-button::after {
  background-color: #4b9afb;
  
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0 !important;
  border: 0 !important;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 8px;
  background-color: transparent;
}

.gid-block {
  position: relative;
  padding-left: 20px;
  padding-top: 5px;
  font-size: 11px;
  color: gray;
  font-weight: 500;
}

.accordion-button {
  padding: 6px 1.25rem 15px;
}

.f-text {
  color: #41a2ff;
  font-weight: 600;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-size: 10px;
  font-family: sans-serif;
}
.s-text {
  font-size: 11px;
  color: black;
  font-weight: 500;
}

.dop-info {
  color: gray;
}

.square-status{
  width: 10px;
  height: 10px;
  background-color: #41a2ff;
  display: inline-block;
  margin-left: 6px;
  margin-top: 2px;

}

.p-calendar {
  font-size: 11px;
  color: gray;
  font-family: sans-serif;
}

.p-calendar {
  display: flex;
  justify-content: flex-end;
}

.nav-tabs .nav-link {
  font-size: 13px;
}

a {
  color: #0d6efd;
  text-decoration: none;
}